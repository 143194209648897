export default () => {
	const componentClass = 'm-tabs';

	function clickActionOutsideAreaDropdown(e) {
		if (
			$(e.target).is($(`.${componentClass}__dropdown`)) === false &&
			$(`.${componentClass}__dropdown`).has(e.target).length === 0 &&
			$(e.target).is($(`.${componentClass}__menu-mobile`)) === false &&
			$(`.${componentClass}__menu-mobile`).has(e.target).length === 0
		) {
			$(`.${componentClass}__dropdown-arrow`).removeClass(
				`${componentClass}__dropdown-arrow--active`,
			);
			$(`.${componentClass}__menu-mobile`).removeClass(
				`${componentClass}__menu-mobile--active`,
			);
		}
	}

	function addActiveClassOnMenu(e) {
		const {
			data: { className, isDesktop },
		} = e;

		$(`.${className}`).removeClass(`${className}--active`);
		$(this).addClass(`${className}--active`);

		// Tab Active Content Item Logic
		let activeIndex = $(this).attr('data-index');
		$(`.${componentClass}__content-item`).removeClass(
			`${componentClass}__content-item--active`,
		);
		$(
			`.${componentClass}__content-item[data-index='${activeIndex}']`,
		).addClass(`${componentClass}__content-item--active`);

		// Menu Indicator Active Position Placement Logic
		if (isDesktop) {
			const tabsCount = Number(
				$(`.${componentClass}`).attr('data-tabs-count'),
			);
			let menuIndicatorPositionIndex = Number(`${activeIndex}`) - 1;

			$(`.${componentClass}__menu-indicator`).css(
				'left',
				`calc(calc(100% / ${tabsCount} * ${menuIndicatorPositionIndex})`,
			);
		}
	}

	// Tab Desktop Menu Items Onclick Logic
	$(`.${componentClass}__menu-desktop-item`).on(
		'click',
		{ className: `${componentClass}__menu-desktop-item`, isDesktop: true },
		addActiveClassOnMenu,
	);

	// Tab Mobile Menu Items Onclick Logic
	$(`.${componentClass}__menu-mobile-item`).on(
		'click',
		{ className: `${componentClass}__menu-mobile-item`, isDesktop: false },
		addActiveClassOnMenu,
	);

	// Mobile Dropdown Menu Toggle Logic
	$(`.${componentClass}__dropdown`).on('click', function(e) {
		e.preventDefault();
		$(`.${componentClass}__menu-mobile`).toggleClass(
			`${componentClass}__menu-mobile--active`,
		);
		$(`.${componentClass}__dropdown-arrow`).toggleClass(
			`${componentClass}__dropdown-arrow--active`,
		);
	});

	// Change Dropdown Main Text When Clicking On Mobile Menu Item And Closing Mobile Menu
	$(`.${componentClass}__menu-mobile-item`).on('click', function() {
		$(`.${componentClass}__dropdown-txt`).text($(this).text());

		if (
			$(`.${componentClass}__menu-mobile`).hasClass(
				`${componentClass}__menu-mobile--active`,
			)
		) {
			$(`.${componentClass}__dropdown-arrow`).removeClass(
				`${componentClass}__dropdown-arrow--active`,
			);
			$(`.${componentClass}__menu-mobile`).removeClass(
				`${componentClass}__menu-mobile--active`,
			);
		}
	});

	// Dropdown Outclick logic
	$(document).on('click', function(e) {
		if (
			$(`.${componentClass}__menu-mobile`).hasClass(
				`${componentClass}__menu-mobile--active`,
			)
		) {
			clickActionOutsideAreaDropdown(e);
		}
	});
};
